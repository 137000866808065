<template>
  <div v-if="eventForm && formOptions && rentRateTable">
    <div class="h4">案件編號：{{ eventForm.numNo || '-' }}</div>
    <div>狀態：
      <span v-if="eventForm.status === 1">通過</span>
      <span v-if="eventForm.status === 2">審核中</span>
      <span v-if="eventForm.status === 3">不通過</span>
      <span v-if="eventForm.status === 4">草稿</span>
    </div>
    <div class="panel mb-3">
      <div class="panel-block">
        <div class="grid-row grid-row-noBottom">
          <div class="col-12_md-6">
            <ul class="labelList">
              <li class="labelList-item">
                <div class="labelList-item-label">活動名稱：</div>
                <div class="labelList-item-content">{{ eventForm.activityName }}</div>
              </li>
              <li class="labelList-item">
                <div class="labelList-item-label">活動名稱(英文)：</div>
                <div class="labelList-item-content">{{ eventForm.activityNameEN }}</div>
              </li>
              <li class="labelList-item">
                <div class="labelList-item-label">檔期屬性：</div>
                <div class="labelList-item-content">{{ optionParser('activitySessionType') }}</div>
              </li>
              <li class="labelList-item">
                <div class="labelList-item-label">活動型式：</div>
                <div class="labelList-item-content">{{ optionParser('activityType') }}</div>
              </li>
            </ul>
          </div>
          <div class="col-12_md-6">
            <ul class="labelList">
              <li class="labelList-item">
                <div class="labelList-item-label">租用範圍：</div>
                <div class="labelList-item-content">
                  {{ optionParser('activityArea') }}
                </div>
              </li>
              <li class="labelList-item">
                <div class="labelList-item-label">售票：</div>
                <div class="labelList-item-content">{{ optionParser('activityCharge') }}</div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="panel-block">
        <div class="panel-block-title"><i class="icon icon-calendar mr-1"></i>檔期時段</div>
        <div class="grid-row grid-row-noBottom">
          <div class="col-12_md-6">
            <div class="h5 mt-2">第一意願</div>
            <ul class="labelList">
              <li class="labelList-item">
                <div class="labelList-item-label">檔期時段：</div>
                <div class="labelList-item-content">
                  {{ dateParser(eventForm.totalStartDate1) }} 至 {{ dateParser(eventForm.totalEndDate1) }}
                </div>
              </li>
              <li class="labelList-item">
                <div class="labelList-item-label">演出時段：</div>
                <div class="labelList-item-content">
                  {{ dateParser(eventForm.showStartDate1) }} 至 {{ dateParser(eventForm.showEndDate1) }}
                </div>
              </li>
              <li class="labelList-item">
                <div class="labelList-item-label">演出日期：</div>
                <div class="labelList-item-content">
                  <ul>
                    <li v-for="session in eventForm.sessionList1" :key="session.showDate">
                      <strong>{{ dateParser(session.showDate) }}</strong>，共 {{ session.sessions }} 場
                    </li>
                  </ul>
                </div>
              </li>
              <li class="labelList-item">
                <div class="labelList-item-label">進撤場日期：</div>
                <div class="labelList-item-content">
                  <ul>
                    <li v-for="session in eventForm.entryExitsList1" :key="session.entryExitDate" style="display: flex;">
                      <strong>{{ dateParser(session.entryExitDate) }}</strong>
                      <ul class="ml-2">
                        <li v-for="time in session.timeDuration" :key="time">{{ timeParser('1', time) }}</li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
          <div class="col-12_md-6">
            <div class="h5 mt-2">第二意願</div>
            <ul class="labelList">
              <li class="labelList-item">
                <div class="labelList-item-label">檔期時段：</div>
                <div class="labelList-item-content">
                  {{ dateParser(eventForm.totalStartDate2) }} 至 {{ dateParser(eventForm.totalEndDate2) }}
                </div>
              </li>
              <li class="labelList-item">
                <div class="labelList-item-label">演出時段：</div>
                <div class="labelList-item-content">
                  {{ dateParser(eventForm.showStartDate2) }} 至 {{ dateParser(eventForm.showEndDate2) }}
                </div>
              </li>
              <li class="labelList-item">
                <div class="labelList-item-label">演出日期：</div>
                <div class="labelList-item-content">
                  <ul>
                    <li v-for="session in eventForm.sessionList2" :key="session.showDate">
                      <strong>{{ dateParser(session.showDate) }}</strong>，共 {{ session.sessions }} 場
                    </li>
                  </ul>
                </div>
              </li>
              <li class="labelList-item">
                <div class="labelList-item-label">進撤場日期：</div>
                <div class="labelList-item-content">
                  <ul>
                    <li v-for="session in eventForm.entryExitsList2" :key="session.entryExitDate" style="display: flex;">
                      <strong>{{ dateParser(session.entryExitDate) }}</strong>
                      <ul class="ml-2">
                        <li v-for="time in session.timeDuration" :key="time">{{ timeParser('1', time) }}</li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="panel-block">
        <div class="panel-block-title"><i class="icon icon-file mr-1"></i>文件</div>
        <div class="grid-row">
          <div class="col-12_md-4">
            <FileInputReadonly
              field-label="活動企劃書(含節目規範概要/演出者)"
              :readonly-info="eventForm.file1"
            />
          </div>
          <div class="col-12_md-4">
            <FileInputReadonly
              field-label="申請單位簡介(含相關實績)"
              :readonly-info="eventForm.file2"
            />
          </div>
          <div class="col-12_md-4">
            <FileInputReadonly
              field-label="演出者意向書或節目方授權文件"
              :readonly-info="eventForm.file3"
            />
          </div>
        </div>
      </div>

      <div class="panel-block">
        <div class="panel-block-title"><i class="icon icon-user mr-1"></i>聯絡人</div>
        <div class="grid-row grid-row-noBottom">
          <div class="col-12_md-6">
            <ul class="labelList">
              <li class="labelList-item">
                <div class="labelList-item-label">主要聯絡人：</div>
                <div class="labelList-item-content">{{ eventForm.contactName }}</div>
              </li>
              <li class="labelList-item">
                <div class="labelList-item-label">聯絡人Email：</div>
                <div class="labelList-item-content">{{ eventForm.contactEmail }}</div>
              </li>
              <li class="labelList-item">
                <div class="labelList-item-label">聯絡人電話：</div>
                <div class="labelList-item-content">{{ eventForm.contactTel }}</div>
              </li>
              <li class="labelList-item">
                <div class="labelList-item-label">聯絡人手機：</div>
                <div class="labelList-item-content">{{ eventForm.contactMobile }}</div>
              </li>
              <li class="labelList-item">
                <div class="labelList-item-label">聯絡地址：</div>
                <div class="labelList-item-content">{{ eventForm.address }}</div>
              </li>
            </ul>
          </div>
          <div class="col-12_md-6">
            <ul class="labelList">
              <li class="labelList-item">
                <div class="labelList-item-label">其他聯絡人：</div>
                <div class="labelList-item-content">{{ eventForm.otherName }}</div>
              </li>
              <li class="labelList-item">
                <div class="labelList-item-label">聯絡人Email：</div>
                <div class="labelList-item-content">{{ eventForm.otherEmail }}</div>
              </li>
              <li class="labelList-item">
                <div class="labelList-item-label">聯絡人電話：</div>
                <div class="labelList-item-content">{{ eventForm.otherTel }}</div>
              </li>
              <li class="labelList-item">
                <div class="labelList-item-label">聯絡人手機：</div>
                <div class="labelList-item-content">{{ eventForm.otherMobile }}</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- 試算表 -->
    <template v-if="eventForm.status === 2 || eventForm.status === 4 && rentEstimate">
      <div class="h4"><i class="icon icon-dollar mr-1"></i>費用試算</div>
      <div class="swipeTable-wrapper">
        <table class="table table-v-center table-center">
          <tbody>
            <tr>
              <th class="fixed-th" width="20%">志願序</th>
              <td>第一意願</td>
              <td>第二意願</td>
            </tr>
            <tr>
              <th class="fixed-th">進撤場日場地租借費用</th>
              <td>{{ rentEstimate[0].entryExitsFee }}元</td>
              <td>{{ rentEstimate[1].entryExitsFee }}元</td>
            </tr>
            <tr>
              <th class="fixed-th">展演日場地租借費用</th>
              <td>{{ rentEstimate[0].showDayFee }}元</td>
              <td>{{ rentEstimate[1].showDayFee }}元</td>
            </tr>
          </tbody>
          <tfoot>
            <tr class="subtotal">
              <th class="fixed-th">合計</th>
              <td>{{ rentEstimate[0].totalFee }}元</td>
              <td>{{ rentEstimate[1].totalFee }}元</td>
            </tr>
            <tr class="guarantee">
              <th class="fixed-th">保證金</th>
              <td>{{ rentEstimate[0].guaranteeFee }}元</td>
              <td>{{ rentEstimate[1].guaranteeFee }}元</td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div class="panel">
        此費用僅供參考，尚不包含電費、管理費及其他銷售營業額等相關衍生費用，亦不包含長租、產業優惠，實際費用以簽約金額為準。
      </div>
    </template>

    <div class="btn-group mt-3 pt-3 pb-3">
      <RouterLink class="btn btn-md btn-secondary" to="/user/project">返回</RouterLink>
      <button v-if="eventForm.status === 4" class="btn btn-md btn-primary" @click="loadDraft(1, eventForm.id)">繼續填寫</button>
    </div>
  </div>
</template>
<script>
import api from '@/api/api';
import { mapActions, mapState } from 'pinia';
import { useFormStore } from '@/store/form';
import { useUserEvents } from '@/store/user';
import FileInputReadonly from '@/components/Input/FileInputReadonly.vue';
import { concertFormula } from '@/utils/estimateFormula';

export default {
  components: {
    FileInputReadonly,
  },
  data() {
    return {
      eventForm: null,
      rentRateTable: null,
    };
  },
  methods: {
    optionParser(optionName) {
      if (!this.eventForm[optionName]) return '-';
      const options = this.formOptions[optionName];
      return options.find((({ id }) => id === this.eventForm[optionName])).name;
    },
    dateParser(dateString) {
      if (!dateString) return '-';
      return this.$moment(dateString).format('YYYY-MM-DD');
    },
    timeParser(optionChargeType, optionVal) {
      const { periodFeeList: options } = this.rentRateTable.find(({ chargeType }) => chargeType === optionChargeType);
      const periodData = options.find(({ periodId }) => periodId === optionVal);
      return periodData?.periodText || optionVal;
    },
    ...mapActions(useUserEvents, ['fetchUserEvent']),
    ...mapActions(useFormStore, ['loadDraft']),
  },
  computed: {
    // 當前試算
    rentEstimate() {
      // if (_.isEmpty(this.rentRateTable)) return null;
      return [
        concertFormula({
          totalStartDate: this.eventForm.totalStartDate1,
          totalEndDate: this.eventForm.totalEndDate1,
          showStartDate: this.eventForm.showStartDate1,
          showEndDate: this.eventForm.showEndDate1,
          sessionList: this.eventForm.sessionList1,
          entryExitsList: this.eventForm.entryExitsList1,
          activityCharge: this.eventForm.activityCharge,
          feeTable: this.rentRateTable,
        }),
        concertFormula({
          totalStartDate: this.eventForm.totalStartDate2,
          totalEndDate: this.eventForm.totalEndDate2,
          showStartDate: this.eventForm.showStartDate2,
          showEndDate: this.eventForm.showEndDate2,
          sessionList: this.eventForm.sessionList2,
          entryExitsList: this.eventForm.entryExitsList2,
          activityCharge: this.eventForm.activityCharge,
          feeTable: this.rentRateTable,
        }),
      ];
    },
    ...mapState(useFormStore, {
      formOptions: (store) => store.formOption.concert,
    }),
  },
  async created() {
    this.eventForm = await this.fetchUserEvent(1, Number(this.$route.params.id));
    this.rentRateTable = await api.getConcertRentRate({ sessionType: this.eventForm.activitySessionType, createTime: this.eventForm.createTime });
  },
};
</script>
